<template>
  <v-form v-model="isValid">
    <v-container fluid>
      <v-row>
        <v-col cols="6">
          <v-text-field :disabled="false" v-model="scheduleParams.fromDays" :label="$t('RELATIVE_FROM_TODAY')" type="number" persistent-hint :hint="fromDaysHint" :rules="[mandatoryRules, validateFirstTextField ]" mandatory> </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field :disabled="false" v-model="scheduleParams.toDays" :label="$t('RELATIVE_TO_TODAY')"  type="number" persistent-hint :hint="toDaysHint" :rules="[mandatoryRules, validateSecondTextField]" mandatory> </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div style="display: flex">
            <v-checkbox v-model="scheduleParams.weekdays" value="mon" class="pr-4" :label="$t('MONDAY')"></v-checkbox>
            <v-checkbox v-model="scheduleParams.weekdays" value="tue" class="pr-4" :label="$t('TUESDAY')"></v-checkbox>
            <v-checkbox v-model="scheduleParams.weekdays" value="wed" class="pr-4" :label="$t('WEDNESDAY')"></v-checkbox>
            <v-checkbox v-model="scheduleParams.weekdays" value="thu" class="pr-4" :label="$t('THURSDAY')"></v-checkbox>
            <v-checkbox v-model="scheduleParams.weekdays" value="fri" class="pr-4" :label="$t('FRIDAY')"></v-checkbox>
            <v-checkbox v-model="scheduleParams.weekdays" value="sat" class="pr-4" :label="$t('SATURDAY')"></v-checkbox>
            <v-checkbox v-model="scheduleParams.weekdays" value="sun" class="pr-4" :label="$t('SUNDAY')"></v-checkbox>
            <v-checkbox v-model="scheduleParams.weekdays" value="hol" class="pr-4" :label="$t('HOLIDAYS')"></v-checkbox>
          </div>
        </v-col>
      </v-row>

    </v-container>
  </v-form>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

export default {
  name: 'SchedulingComponent',

  components: {},

  props: {
    scheduleParams: Object,
    phoneCampaign: Boolean
  },

  data: () => ({
    isValid: true
  }),

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

    validateFirstTextField() {
      return v=> Number(v)<Number(this.scheduleParams.toDays) || this.$t('MUST_BE_LESS_THAN') + ' ' + this.scheduleParams.toDays
    },

    validateSecondTextField() {
      return v=> Number(v)>Number(this.scheduleParams.fromDays) || this.$t('MUST_BE_LARGER_THAN')+ ' ' + this.scheduleParams.fromDays
    },

    fromDaysHint() {
      if(this.scheduleParams.fromDays) {
        const days = this.scheduleParams.fromDays>0 ? '+'+this.scheduleParams.fromDays : this.scheduleParams.fromDays;
        return this.$t('TODAY') + ' ' + days + ' ' + this.$t('DAYS')
      }
      return ''
    },

    toDaysHint() {
      if(this.scheduleParams.toDays) {
        const days = this.scheduleParams.toDays>0 ? '+'+this.scheduleParams.toDays : this.scheduleParams.toDays;
        return this.$t('TODAY') + ' ' + days + ' ' + this.$t('DAYS')
      }
      return ''
    },

    mandatoryRules() {

      return value => {
        if(parseInt(value) === 0) return true
       else return !!value || this.$t('VALIDATE_ERROR_MANDATORY') + value
      }
    },
  },

  watch: {
    isValid: function (newValue) {
      this.$emit('component-valid', newValue)
    }
  }



}
</script>

<style scoped>

</style>
