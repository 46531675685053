<template>

  <v-form v-model="isValid">

    <v-row>
      <v-col>

        <v-autocomplete v-if="campaignDetails.sendingType!=='PHONE'"
            v-model="campaignDetails.templateId"
            :no-data-text="$t('MAIL_CAMPAIGN_PICK_TEMPLATE')"
            :label="$t('MAIL_CAMPAIGN_PICK_TEMPLATE')"
            auto-select-first
            :items="campaignConfiguration.templates"
            item-text="title"
            item-value="id"
            :rules="mandatoryRules"
        >
          </v-autocomplete>

        <v-autocomplete v-else
                        v-model="campaignDetails.callTemplate"
                        :no-data-text="$t('MAIL_CAMPAIGN_PICK_TEMPLATE')"
                        :label="$t('MAIL_CAMPAIGN_PICK_TEMPLATE')"
                        auto-select-first
                        :items="campaignConfiguration.templates"
                        item-text="title"
                        item-value="id"
                        :rules="mandatoryRules"
        >
        </v-autocomplete>




      </v-col>

      <v-col>

        <v-dialog width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="viewButton" text v-bind="attrs" v-on="on">{{'MAIL_CAMPAIGN_PREVIEW' | i18n}}</v-btn>
          </template>
          <RenderedMail :campaign-id="campaignDetails.id" :template="''+campaignDetails.templateId" :sending-type="campaignDetails.sendingType" :mail-action="campaignDetails.mailAction" />
        </v-dialog>
      </v-col>

    </v-row>

    <v-autocomplete
        :disabled="campaignConfiguration.fromEmails.length===0"
        v-model="campaignDetails.fromEmail"
        :label="$t('MAIL_CAMPAIGN_PICK_SENDER')"
        auto-select-first
        :items="campaignConfiguration.fromEmails"
        :rules="mandatoryRules">
    </v-autocomplete>

    <v-text-field v-if="campaignDetails.sendingType==='EMAIL'" v-model="campaignDetails.fromName" :label="$t('MAIL_SENDER_NAME')" mandatory :rules="mandatoryRules"></v-text-field>
    <v-text-field v-if="campaignDetails.sendingType==='EMAIL'" v-model="campaignDetails.bcc" :label="$t('MAIL_CAMPAIGN_BCC')" ></v-text-field>
    <v-text-field v-if="campaignDetails.sendingType==='EMAIL'" v-model="campaignDetails.replyTo" :label="$t('MAIL_CAMPAIGN_REPLY_TO')" ></v-text-field>
    <v-text-field v-if="canEditFixedRecipient && campaignDetails.sendingType==='EMAIL'" v-model="campaignDetails.fixedRecipient" :label="$t('FIXED_RECIPIENT')" ></v-text-field>
  </v-form>


</template>

<script>
import {mapGetters, mapState} from 'vuex'
import RenderedMail from "@/views/mailcampaigns/RenderedMail.vue";

export default {
  name: 'TemplateCard',

  components: {RenderedMail},

  props: {
    campaignDetails: Object,
    campaignConfiguration: Object
  },

  created() {},

  data: () => ({
    // selectedTemplate: null,
    fromEmails: [],
    mailTemplates: [],
    fromName: null,
    isValid: null
  }),

  computed: {
    mandatoryRules() {
      return  [
        value => !!value || this.$t('VALIDATE_ERROR_MANDATORY'),
      ]
    },

    canEditFixedRecipient() {
      return this.campaignDetails.campaignType==='PUSH'
    },

    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),
  },

  methods: {
  },

  watch: {
    isValid: function (newValue) {
      this.$emit('tab-valid', newValue)
    }
  }
}
</script>

<style scoped>

</style>
