<template>
  <v-container fluid pa-0>
    <ConfirmDialog ref="confirm" />

    <v-row>
      <v-col class="pt-0 pb-0">
        <DrillPathComponent/>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-0 pb-0">
        <DrillPathBackComponent/>
      </v-col>
    </v-row>

    <v-row class="pt-3 px-3 pb-0">

      <v-col cols="9">
        <v-card>

          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">{{'MAIL_CAMPAIGN_PICK_NAME' | i18n}}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 2" step="2">{{'MAIL_CAMPAIGN_PICK_TEMPLATE' | i18n}}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 3" step="3">{{'MAIL_CAMPAIGN_CHECK_DATA' | i18n}}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="4">{{'MAIL_CAMPAIGN_START' | i18n}}</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form v-model="step1FormValid">
                  <div class="stepCaption">{{'MAIL_CAMPAIGN_PICK_NAME' | i18n}}</div>

                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field :label="$t('MAIL_CAMPAIGN_NAME_OF')" v-model="campaignName" :rules="mandatoryRules" mandatory> </v-text-field>
                      </v-col>
                      <v-col cols="2" v-if="isUserRoot">
                        <v-text-field label="extname" v-model="campaignExtName"> </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-select :items="sendingTypes" v-model="sendingType" @change="onSendingTypeChange" :label="$t('MAIL_CAMPAIGN_METHOD')" :rules="mandatoryRules" mandatory></v-select>
                      </v-col>
                      <v-col cols="2">
                        <v-select :items="campaignTypes" v-model="pushPullType" :label="$t('MAIL_CAMPAIGN_TYPE')" :rules="mandatoryRules" mandatory></v-select>
                      </v-col>
                    </v-row>

                    <v-row v-if="showScheduling">
                      <SchedulingComponent :phone-campaign="isPhoneCampaign" :schedule-params="scheduleParams"  ref="schedulingComponent" />
                    </v-row>

                  </v-container>

                </v-form>

                <div class="buttonContainer">
                  <v-btn color="primary" @click="e1 = 2" :disabled="!step1FormValid || ($refs['schedulingComponent'] && !$refs['schedulingComponent'].isValid)">{{'NEXT' | i18n}}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>

              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form v-model="step2FormValid">

                  <div class="stepCaption">{{'MAIL_CAMPAIGN_PICK_TEMPLATE' | i18n}}</div>

                  <v-autocomplete
                      :disabled="mailTemplates.length===0"
                      v-model="selectedTemplate"
                      :no-data-text="$t('MAIL_CAMPAIGN_PICK_TEMPLATE')"
                      :loading="isLoadingTemplates"
                      :label="$t('MAIL_CAMPAIGN_PICK_TEMPLATE')"
                      auto-select-first
                      :items="mailTemplates ? mailTemplates : []"
                      item-text="title"
                      item-value="id"
                      :rules="mandatoryRules"
                      return-object>
                  </v-autocomplete>

                  <v-text-field v-if="sendingType==='EMAIL'" v-model="fromName" :label="$t('MAIL_SENDER_NAME')" :rules="mandatoryRules"></v-text-field>

                  <v-autocomplete v-if="sendingType==='EMAIL' || sendingType==='SMS' || sendingType==='PHONE'"
                      :disabled="campaignInfo.fromEmails.length===0"
                      v-model="fromEmail"
                      :label="$t('MAIL_CAMPAIGN_PICK_SENDER')"
                      auto-select-first
                      :items="campaignInfo.fromEmails"
                      :rules="mandatoryRules">
                  </v-autocomplete>

                  <v-text-field v-if="sendingType==='EMAIL'" v-model="bcc" :label="$t('MAIL_CAMPAIGN_BCC')" ></v-text-field>
                  <v-text-field v-if="sendingType==='EMAIL'" v-model="replyTo" :label="$t('MAIL_CAMPAIGN_REPLY_TO')" ></v-text-field>
                  <v-text-field v-if="canEditFixedRecipient && sendingType==='EMAIL'" v-model="fixedRecipient" :label="$t('FIXED_RECIPIENT')" ></v-text-field>

                  <template v-if="sendingType==='LETTER'">
                    <LetterTemplateHelp/>
                  </template>


                  <v-autocomplete v-if="isPhoneCampaign"
                      :disabled="voices.length===0"
                      v-model="selectedVoice"
                      :no-data-text="$t('MAIL_CAMPAIGN_PICK_VOICE')"
                      :loading="isLoadingVoices"
                      :label="$t('MAIL_CAMPAIGN_PICK_VOICE')"
                      auto-select-first
                      :items="voices ? voices : []"
                      item-text="title"
                      item-value="id"
                      :rules="mandatoryRules">
                  </v-autocomplete>

                  <v-autocomplete v-if="isPhoneCampaign"
                                  :disabled="locales.length===0"
                                  v-model="selectedLocale"
                                  :no-data-text="$t('MAIL_CAMPAIGN_PICK_LOCALE')"
                                  :label="$t('MAIL_CAMPAIGN_PICK_LOCALE')"
                                  auto-select-first
                                  :items="locales"
                                  item-text="title"
                                  item-value="id"
                                  :rules="mandatoryRules">
                  </v-autocomplete>




                </v-form>

                <div class="buttonContainer2">
                  <v-btn color="primary" @click="e1 = 1" >
                    <v-icon left dark>mdi-chevron-left</v-icon>
                    {{ 'PREV' | i18n }}
                  </v-btn>
                  <v-btn color="primary" @click="e1 = 3" :disabled="!isStep2Valid">{{ 'NEXT' | i18n }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>


              </v-stepper-content>

              <!----------------------------- SELECT FILTERS -------------------------------->
              <v-stepper-content step="3">

                <v-container fluid pa-0>
                  <v-row>
                    <v-col><div class="stepCaption">{{'MAIL_CAMPAIGN_CHECK_DATA' | i18n}}</div></v-col>
                  </v-row>

                  <v-row v-for="mailFilter in selectableFilters" :key="mailFilter.extname" class="align-center mt-0">
                    <v-col cols="2" class="pt-0 pb-0">{{mailFilter.title}}</v-col>
                    <v-col cols="1" class="pt-0  pb-0">{{mailFilter.nInterviews}}</v-col>
                    <v-col cols="1" class="pt-0  pb-0"><v-switch v-model="activeFilters" :value="mailFilter.extname" @change="onFilterChanged(mailFilter)" :label="'DELETE' | i18n"></v-switch>   </v-col>
                    <v-col cols="4" class="pt-0 pb-0">{{mailFilter.help}}</v-col>
                  </v-row>


                </v-container>

                <div class="buttonContainer2">
                  <v-btn color="primary" @click="e1 = 2" :disabled="!step2FormValid">
                    <v-icon left dark>mdi-chevron-left</v-icon>{{ 'PREV' | i18n }}
                  </v-btn>
                  <v-btn color="primary" @click="e1 = 4">{{ 'NEXT' | i18n }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>

              </v-stepper-content>

              <v-stepper-content step="4">
                <div class="stepCaption">{{'MAIL_CAMPAIGN_START' | i18n}}</div>

                <v-container fluid pa-0>
                  <v-row>
                    <v-col cols="5">
                      <v-text-field readonly disabled :label="$t('NAME')" v-model="campaignName"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>

                    <v-col cols="5">
                      <v-text-field readonly disabled :label="$t('MAIL_TEMPLATE')" v-model="selectedTemplate.name" v-if="selectedTemplate"></v-text-field>
                    </v-col>

                    <v-col cols="2">
                      <v-select readonly disabled :items="sendingTypes" v-model="sendingType" :label="$t('MAIL_CAMPAIGN_METHOD')" mandatory></v-select>
                    </v-col>

                  </v-row>

                </v-container>

                <div class="buttonContainer2">
                  <v-btn color="primary" @click="e1 = 3" >
                    <v-icon left dark>mdi-chevron-left</v-icon>{{ 'PREV' | i18n }}
                  </v-btn>

                  <div>

                    <v-dialog width="auto " scrollable>
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" v-on="on" :disabled="recipentsListData.rows.length==0">{{'MAIL_CAMPAIGN_PREVIEW' | i18n}}<v-icon right dark>mdi-eye</v-icon></v-btn>
                      </template>
                      <RenderedMailCarousel :campaign-id="0" :template-ref="selectedTemplate.id" :tracking-ids="trackingIds" v-if="selectedTemplate"/>
                    </v-dialog>

                    <v-dialog v-model="scheduleDialog" width="40%">
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" v-on="on" class="ml-2 mr-2">{{'MAIL_CAMPAIGN_SCHEDULE' | i18n}}<v-icon right dark>mdi-calendar</v-icon></v-btn>
                      </template>

                      <v-card>
                        <v-form v-model="scheduleDialogValid">

                        <v-container fluid>
                          <v-row>
                            <v-col cols="6">
                              <v-text-field v-model="scheduledStartDateTime"
                                            :hint="$t('MAIL_CAMPAIGN_SCHEDULE_HINT')"
                                            persistent-hint
                                            :label="$t('MAIL_CAMPAIGN_SCHEDULE')"
                                            type="datetime-local"
                                            :rules="mandatoryRules"
                                            mandatory
                              />
                            </v-col>
                          </v-row>

                          <v-row v-if="showScheduling">
                            <v-col cols="6">
                              <v-radio-group row :label="$t('SENDING')" v-model="scheduledPeriod">
                                <v-radio :label="$t('MAIL_CAMPAIGN_DAILY')" value="DAILY"></v-radio>
                                <v-radio :label="$t('MAIL_CAMPAIGN_WEEKLY')" value="WEEKLY"></v-radio>
                                <v-radio :label="$t('MAIL_CAMPAIGN_MONTHLY')" value="MONTHLY"></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>

                        </v-container>

                        <v-card-actions>
                          <div class="flex-grow-1"></div>
                          <v-btn color="primary" text @click="scheduleDialog = false">{{'CANCEL' | i18n}}</v-btn>
                          <v-btn color="primary" text @click="onScheduleCampaign" :disabled="!scheduleDialogValid">{{'MAIL_CAMPAIGN_SCHEDULE' | i18n}}</v-btn>
                        </v-card-actions>

                        </v-form>
                      </v-card>

                    </v-dialog>

                    <v-btn v-if="!showScheduling" color="primary" @click="onStartCampaign">{{'MAIL_CAMPAIGN_START' | i18n}}<v-icon right dark>mdi-play</v-icon></v-btn>

                  </div>
                </div>
              </v-stepper-content>

            </v-stepper-items>

          </v-stepper>

        </v-card>

      </v-col>

      <v-col cols="3">

        <div>

          <v-card>

            <div style="padding: 1em">
              <div style="font-size: calc((30/16)*1rem); color: black">
                {{ campaignInfo.numberCustomers }}
              </div>
              <div style="font-weight: bold;margin-bottom: 1em">
                {{'MAIL_CAMPAIGN_CUSTOMERS_SELECTED' | i18n}}
              </div>

            </div>

          </v-card>

          <v-card style="margin-top: 1em">
            <div style="padding: 1em; display: flex;flex-direction: column">
              <div style="font-size: calc((30/16)*1rem); color: black">{{campaignInfo.pathName}}</div>
              <div style="font-weight: bold; margin-bottom: 1em">{{'MAIL_CAMPAIGN_SELECTED_FILTERS' | i18n}}</div>

              <template v-for="(filterName,idx) in campaignInfo.filters">
                <div :key="idx">{{filterName}}<v-icon>mdi-check</v-icon> </div>
              </template>

              <template v-for="mailFilter in selectedFilters">
                <div :key="mailFilter.extname">{{mailFilter.title}}<v-icon>mdi-check</v-icon> </div>
              </template>

            </div>

          </v-card>

        </div>

      </v-col>

    </v-row>

    <v-row class="pt-0 px-3" v-if="e1===2 && selectedTemplate">
      <v-col cols="9">
        <v-card>
          <SMSTemplateDetails v-if="sendingType==='SMS' && selectedTemplate" :embedded="true" :template-id="selectedTemplate.id" :key="selectedTemplate.id"/>
          <MailTemplateDetails v-else-if="sendingType==='EMAIL' && selectedTemplate" :embedded="true" :template-id="selectedTemplate.id" :key="selectedTemplate.id" :mail-action="mailAction"  />
          <LetterTemplateDetails v-else-if="sendingType==='LETTER'  && selectedTemplate" :embedded="true" :template-id="selectedTemplate.id" :key="selectedTemplate.id"/>
        </v-card>
      </v-col>
    </v-row>



    <v-row class="pt-3 px-3 pb-0" v-if="e1==4">
      <v-col>
        <v-card>
          <v-data-table
              :headers="headers"
              :server-items-length="recipentsListData.totalRows"
              :items="recipentsListData.rows"
              @update:options="onOptionsChanged"
              :options.sync="options"
              :loading="loadingRecipentsData"
              item-key="id"
              class="elevation-2"
              :items-per-page="50"
              must-sort
              :sort-by.sync="sortBy">

            <template v-slot:top>
              <v-row>
                <v-col>
                  <v-text-field v-model="search" label="Filter" class="mx-4" @change="onFilterEnter"></v-text-field>
                </v-col>
              </v-row>
            </template>

            <template v-slot:item.viewTemplate="{ item }">
              <v-dialog width="auto" >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :disabled="!selectedTemplate"  class="viewButton" text v-bind="attrs" v-on="on">{{'MAIL_CAMPAIGN_PREVIEW' | i18n}}</v-btn>
                </template>

                <RenderedMail :campaign-id="0" :template="selectedTemplate.id" :tracking-id="item.id" v-if="selectedTemplate" :mail-action="mailAction" :sending-type="sendingType"  />

              </v-dialog>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon small @click="onDeleteItem(item)">mdi-delete</v-icon>
            </template>

          </v-data-table>

        </v-card>

      </v-col>

    </v-row>


  </v-container>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import DrillPathComponent from "@/components/DrillPathComponent.vue";
import DrillPathBackComponent from "@/components/DrillPathBackComponent.vue";
import MailsService from "@/services/MailsService";
import RenderedMail from "@/views/mailcampaigns/RenderedMail.vue";
import Pagenavigator from "@/pagenavigator";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import RenderedMailCarousel from "@/views/mailcampaigns/RenderedMailCarousel.vue";
import MailTemplateDetails from "@/views/mailtemplates/MailTemplateDetails.vue";
import SMSTemplateDetails from "@/views/mailtemplates/SMSTemplateDetails.vue";
import SchedulingComponent from "@/views/mailcampaigns/SchedulingComponent.vue";
import LetterTemplateDetails from "@/views/mailtemplates/LetterTemplateDetails.vue";
import UploadService from "@/services/UploadService";
import LetterTemplateHelp from "@/views/mailcampaigns/LetterTemplateHelp.vue";

export default {
  name: 'CreateCampaignPage',

  components: {
    LetterTemplateHelp,
    LetterTemplateDetails,
    SchedulingComponent,
    SMSTemplateDetails,
    MailTemplateDetails,
    RenderedMailCarousel,
    ConfirmDialog,
    RenderedMail,
    DrillPathBackComponent,
    DrillPathComponent
  },

  created: function () {
    this.sendingType = 'EMAIL'
    this.activeFilters = []
    this.pushPullType = null
    this.fetchCampaignData(this.sendingType);
    this.loadTemplates(this.sendingType)
  },

  data() {
    return {
      renderedPDFBytes: null,

      currentFile: undefined,
      progress: 0,

      initialDataFetched: false,

      e1: 1,

      scheduleDialog: false,
      scheduleDialogValid: false,
      scheduledStartDateTime: null,
      scheduledPeriod: 'DAILY',
      campaignName: "",
      campaignExtName: "",
      sendingType: "",
      pushPullType: "",
      mailSubject: "",
      fromEmail: "",
      fromName: "",

      bcc: "",
      replyTo: "",
      fixedRecipient: "",

      step1FormValid: false,
      step2FormValid: false,

      campaignInfo: {
        numberCustomers: "",
        pathName: "",
        filters: [],
        fromEmails: [],
        mailFilters: [],
      },

      selectedTemplate: null,
      mailTemplates: [""],
      isLoadingTemplates: false,

      selectedVoice: null,
      isLoadingVoices: false,
      voices: [{ id: "peter", title: "Peter"}, { id: "arne", title: "Arne"}],

      selectedLocale: null,
      locales: [
        { id: "da", title: "Danish"},
        { id: "nl", title: "Dutch"},
        { id: "en", title: "English"},
        { id: "en-US", title: "English (US)"},
        { id: "en-GB", title: "English (UK)"},
        { id: "fi", title: "Finnish"},
        { id: "fr", title: "French"},
        { id: "de", title: "German"},
        { id: "it", title: "Italian"},
        { id: "es", title: "Spanish"},
        { id: "sv", title: "Swedish"} ],


      scheduleParams: {
        fromDays: -30,
        toDays: 0,
        weekdays: ['mon','tue','wed','thu','fri', 'hol'],
      },

      ////////////////////////////////////////////////////////////////////
      // recipients table
      search: '',

      options: {},

      loadingRecipentsData: false,
      recipentsListData: {
        rows: [],
        totalRows: 0
      },

      sortBy: 'id',

      deletedRows: [],

      activeFilters: [],
    }
  },

  computed: {
    ...mapState(['activeSurvey', 'interviewData']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

    showScheduling() {
      return this.pushPullType==='PULL'
    },

    isPushCampaign() {
      return this.pushPullType==='PUSH'
    },

    isEmailCampaign() {
      return this.sendingType==='EMAIL'
    },

    isSMSCampaign() {
      return this.sendingType==='SMS'
    },

    isLetterCampaign() {
      return this.sendingType==='LETTER'
    },

    isPhoneCampaign() {
      return this.sendingType==='PHONE'
    },

    campaignTypes() {
      return this.isUserRoot ? [
        { text: this.$t('MAIL_CAMPAIGN_TYPE_ONE_SHOT'), value: 'PULL_ONCE' },
        { text: this.$t('MAIL_CAMPAIGN_TYPE_AUTO_ADD'), value: 'PULL' },
        { text: this.$t('MAIL_CAMPAIGN_TYPE_PUSH'), value: 'PUSH' },
      ] : [
        { text: this.$t('MAIL_CAMPAIGN_TYPE_ONE_SHOT'), value: 'PULL_ONCE' },
        { text: this.$t('MAIL_CAMPAIGN_TYPE_AUTO_ADD'), value: 'PULL' }
      ]
    },

    mailAction() {
      return this.$route.params.mailAction
    },

    headers() {

      return [
        { text: this.$t('NAME'), align: 'left', value: 'respondent' },
        { text: this.sendingType==='SMS' ? this.$t('PHONE') : this.$t('EMAIL_FOR_CAMPAIGN'), align: 'left', value: 'email' },
        { text: this.$t('STATE_FILTER'), align: 'left', value: 'status' },
        { text: this.$t('MAIL_CAMPAIGN_PREVIEW'), align: 'left', value: 'viewTemplate' },
        { text: this.$t('ACTIONS'), value: 'actions', sortable: false },
      ];

    },

    sendingTypes() {
      const result = []

      if(this.campaignInfo && this.campaignInfo.mailCampaigns) {
        result.push( { text: this.$t('MAIL_CAMPAIGN_METHOD_EMAIL'), value: "EMAIL" } )
      }

      if(this.campaignInfo && this.campaignInfo.smsCampaigns) {
        result.push( {text: this.$t('MAIL_CAMPAIGN_METHOD_TEXT'), value: "SMS" } )
      }

      if(this.campaignInfo && this.campaignInfo.letterCampaigns) {
        result.push( {text: this.$t('MAIL_CAMPAIGN_METHOD_LETTER'), value: "LETTER" } )
      }

      if(this.campaignInfo && this.campaignInfo.phoneCampaigns) {
        result.push( {text: this.$t('MAIL_CAMPAIGN_METHOD_PHONE'), value: "PHONE" } )
      }


      return result
    },

    isStep2Valid() {
      return (this.isPhoneCampaign && this.selectedTemplate && this.selectedVoice && this.selectedLocale) ||
          (this.step2FormValid &&
          ((this.isEmailCampaign && this.selectedTemplate) ||
           (this.isSMSCampaign && this.selectedTemplate) ||
           (this.isLetterCampaign) ||
           (this.isPhoneCampaign)))
    },

    mandatoryRules() {
      return  [
        value => !!value || this.$t('VALIDATE_ERROR_MANDATORY'),
      ]
    },

    selectableFilters() {
      return this.campaignInfo.mailFilters.filter(mailFilter => mailFilter.extname!=='illegal')
    },

    selectedFilters() {
      return this.campaignInfo.mailFilters.filter(mailFilter => this.activeFilters.includes(mailFilter.extname) || mailFilter.extname==='illegal')
    },

    unselectedFilters() {
      return this.campaignInfo.mailFilters.filter(mailFilter => !this.activeFilters.includes(mailFilter.extname))
    },

    trackingIds() {
      return this.recipentsListData.rows.map(e => e.id)
    },

    canEditFixedRecipient() {
      return this.campaignInfo.editFixedRecipient || this.isPushCampaign
    }

  },

  methods: {

    // selectFile(file) {
    //   this.progress = 0;
    //   this.currentFile = file;
    // },

    // onClickUploadLetterTemplate() {
    //   UploadService.uploadLetterTemplate(this.getAuthToken, this.activeSurvey.extName, this.currentFile,  (event) => {
    //     this.progress = Math.round((100 * event.loaded) / event.total);
    //   }).then((response) => {
    //     this.selectedTemplate = response.data
    //     this.renderedPDFBytes = "data:application/octet-stream;base64," + response.data.renderedPDFBytes;
    //
    //     this.message = response.data.message;
    //     this.uploadResponse = response.data
    //       })
    //       .catch(() => {
    //         this.progress = 0;
    //         // this.message = "Could not upload the file!";
    //         this.currentFile = undefined;
    //       });
    //
    // },

    onSendingTypeChange() {
      this.selectedTemplate = null
      this.fetchCampaignData(this.sendingType)
      this.loadTemplates(this.sendingType)

      if(this.isPhoneCampaign) {
        this.loadVoices()
      }

    },


    loadTemplates(sendingType) {
      this.mailTemplates = []

      this.isLoadingTemplates = true

      MailsService.fetchLocalMailTemplatesList(this.getAuthToken, this.activeSurvey.extName, sendingType, this.$route.params.mailAction).then((response) => {
        this.mailTemplates = response.data
        this.isLoadingTemplates = false
      })

    },

    loadVoices() {
      this.voices = []

      this.isLoadingVoices = true

      MailsService.fetchVoicesList(this.getAuthToken, this.activeSurvey.extName).then((response) => {
        this.voices = response.data
        this.isLoadingVoices = false
      })

    },




    onFilterEnter() {
      this.onOptionsChanged(this.options)
    },

    onFilterChanged(/*filter*/) {
      this.fetchCampaignData(this.sendingType);
    },

    isFilterActive(filterName) {
      return this.activeFilters.contains(filterName)
    },

    fetchCampaignData(sendingType) {
      MailsService.fetchCreateCampaignData(this.getAuthToken, this.activeSurvey.extName, this.$route.params.mailAction, this.getFilteredDrillPath, this.activeFilters, this.deletedRows, sendingType, !this.initialDataFetched).then((response) => {
        this.campaignInfo = response.data

        if(this.campaignInfo.fromEmails.length>0) {
          this.fromEmail = this.campaignInfo.fromEmails[0]
        }

        if(!this.fromName || this.fromName==='') {
          this.fromName = this.campaignInfo.fromName
        }

        this.sendingType = sendingType

        if(!this.initialDataFetched) {
          this.activeFilters = this.selectableFilters.filter(f => f.defaultEnabled).map(f=>f.extname)
          this.initialDataFetched = true
        }

      })
    },

    onOptionsChanged(options) {
      const pageNumber = options.page
      const pageSize = options.itemsPerPage

      const from = (pageNumber-1)*pageSize
      const to = from + pageSize

      const sortBy = options.sortBy[0]
      const sortDesc = options.sortDesc[0]

      this.loadingRecipentsData = true

      MailsService.fetchNewMailTrackingRows(this.getAuthToken, this.activeSurvey.extName, this.$route.params.mailAction, this.getFilteredDrillPath, this.sendingType, from, to, sortBy, sortDesc, this.search, this.activeFilters, this.deletedRows, this.fixedRecipient).then((response) => {
        this.recipentsListData = response.data
        this.loadingRecipentsData = false
      })

    },

    onDeleteItem(row) {
      this.deletedRows.push(row.id)
      this.onOptionsChanged(this.options)
      this.fetchCampaignData(this.sendingType)
    },

    async onStartCampaign() {
      this.scheduleDialog = false
      this.scheduledStartDateTime = null
      this.showStartConfirmation()
    },

    onScheduleCampaign() {
      this.scheduleDialog = false
      this.showStartConfirmation()
    },

    async showStartConfirmation() {
      const details = (!this.isPushCampaign) ?
          this.campaignName + "(" + this.campaignInfo.numberCustomers + " " + this.$t('RECIPIENTS')  + ")" :
          this.campaignName

      let title = this.$t('MAIL_CAMPAIGN_START') + " " + details

      if(this.scheduledStartDateTime) {
        const d = new Date(this.scheduledStartDateTime)
        const hour = String(d.getHours()).padStart(2, '0');
        const minute = String(d.getMinutes()).padStart(2, '0');

        const datetime = hour + ":" + minute + " " + d.toLocaleDateString()
        title = "Schedule " + details + " for start at " + datetime
      }

      if ( await this.$refs.confirm.open(this.$t('CONFIRM'), title  )) {

        MailsService.startMailCampaignNow(
            this.getAuthToken, this.activeSurvey.extName, this.$route.params.mailAction,
            this.getFilteredDrillPath, this.campaignName, this.campaignExtName, this.sendingType, this.selectedTemplate.id,
            this.fromName, this.fromEmail, this.activeFilters, this.deletedRows, this.scheduledStartDateTime, this.scheduledPeriod,
            this.selectedVoice, this.selectedLocale,
            this.pushPullType, this.bcc, this.replyTo, this.fixedRecipient, this.scheduleParams).then((/*response*/) => {


          this.$store.commit('showSnackbar', this.campaignName + " is now running")
          Pagenavigator.navigateToMailCampaignOverviewPage(this, this.activeSurvey.extName, this.getFilteredDrillPath)
        })
      }
    },


  }
}

</script>
<style scoped>
div.stepCaption {
  /*font-size: 30px;*/
  font-size: calc((30/16)*1rem);
  margin-bottom: 1em;
}

div.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

div.buttonContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


</style>