<template>
    <v-container fluid v-if="isSelectingUniverseByIndicators && !isChangingUniverse">
        <v-row>
            <GlobalOverviewComponent @select="e=>onUniverseSelect(e.universeMetaInterview.universe, e.host)" :username="userIdentity.username" :global-overview-data="userIdentity.globalOverviewData"/>
        </v-row>
    </v-container>

    <v-container v-else fluid fill-height>
        <v-row align-center justify-center>
            <v-col offset=4 cols="4" class="logocol">
                <img v-if="isVGSServer" height="52px" width="220px" src="../assets/vgs_logo.png">
                <img v-else-if="isFTZServer" height="130px" width="267px" src="../assets/ftz_logo.png">
                <img v-else-if="isAutoFlowsServer" height="175px" width="557px" src="../assets/new_autoflows_logo.png">
                <img v-else-if="isPostNordServer" height="52px" width="220pxpx" src="../assets/postnord_logo.png">
                <img v-else width="220px" src="../assets/login_logo.png">
            </v-col>

            <v-col offset=4 cols="4">
                <v-card class="elevation-12 pa-10">

                    <template v-if="isUnknownUser">
                        <v-card-title class="mainTitle">Your login is almost ready</v-card-title>
                        <v-card-subtitle class="subTitle">Your administrator needs to setup and verify your user profile. You will be notified when your account is activated.</v-card-subtitle>
                        <v-card-actions>
                            <v-btn @click="onLogoutClicked">logout</v-btn>
                        </v-card-actions>
                    </template>

                  <template v-else-if="isSuggestDifferentLoginWithNewUser">
                    <v-card-title class="mainTitle">Login with a different user</v-card-title>
                    <v-card-subtitle class="subTitle">This user is not configured for this server.</v-card-subtitle>
                    <v-card-actions>
                      <v-btn @click="onLogoutClicked">logout</v-btn>
                    </v-card-actions>
                  </template>

                  <template v-else-if="isSelectingActAsUniverseUser">
                    <v-card-title>Logged in as : {{ keyCloakUserName }}</v-card-title>
                    <v-card-text>
                      <v-autocomplete :items="userIdentity.universeAdminUserList" v-model="actAsSelectedUniverse" item-text="universeName" label="Universe" @change="onSelectedActAsUniverse" return-object></v-autocomplete>
                      <v-autocomplete label="Impersonate" :items="actAsUsers" v-model="actAsSelectedUser" @change="onSelectedActAsUser"></v-autocomplete>
                      <v-btn :disabled="actAsSelectedUniverse===null || actAsSelectedUser===null" @click="onSelectedActAsUser">Impersonate</v-btn>
                      <div v-if="errorMessage">{{errorMessage}}</div>
                    </v-card-text>
                    <v-card-actions style="flex-direction: row-reverse;">
                      <v-btn text @click="onLogoutClicked">Logout</v-btn>
                    </v-card-actions>
                  </template>


                  <template v-else-if="isSelectingUniverse">
                        <v-card-text>
                            <v-list>
                                <v-list-item-group>
                                    <v-list-item v-for="(universeAccessRef,idx) in userIdentity.universes" :key="idx" @click="onUniverseSelect(universeAccessRef.name, universeAccessRef.host)">
                                        <!--                  <v-list-item v-for="(uniTitle, uniName) in userIdentity.universes" :key="uniName" @click="onUniverseSelect({universe: uniName})">-->
                                        <v-list-item-icon>
                                            <TinyLogoComponent :universe="universeAccessRef.name" />
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="universeAccessRef.title"></v-list-item-title>
                                            <v-list-item-subtitle v-text="universeAccessRef.host"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-card-text>
                      <v-card-actions style="flex-direction: row-reverse;">
                        <v-btn text @click="onLogoutClicked">Logout</v-btn>
                      </v-card-actions>

                    </template>

                    <template v-else>
                        <v-card-title class="mainTitle">{{textWelcome}}</v-card-title>
                        <v-card-subtitle class="subTitle">{{textSignIn}}</v-card-subtitle>
                        <div class="waitContainer" >
                            <v-progress-circular v-if="showLoadCounter" :size="100" :width="4" color="#0000c8" :value="loadCounter">
                                {{ loadCounter }}%
                            </v-progress-circular>
                            <v-progress-circular v-else :size="100" :width="4" color="#0000c8" indeterminate/>
                        </div>


                    </template>

                    <div v-if="showWaitScreen" class="waitContainer">
                        <v-progress-circular
                                :size="100"
                                :width="4"
                                color="#0000c8"
                                indeterminate
                        />

                    </div>
                </v-card>
            </v-col>
        </v-row>



        <v-row no-gutters class="footerRow">
            <img v-if="isVGSServer" style="margin-bottom: 14px" width="220px" src="../assets/login_logo.png">
            <img v-else-if="isFTZServer" style="margin-bottom: 14px" width="220px" src="../assets/ag_autoflows_logo.png">
            <div>ag analytics &middot; +45 7020 1075 &middot; <a class="frontLink" href="mailto:support@aganalytics.dk">{{textSupportMail}}</a></div>
        </v-row>


    </v-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import AuthService from "../services/AuthService";
import PageNavigator from "../pagenavigator"
import _ from 'lodash'
import TinyLogoComponent from "../components/TinyLogoComponent";
import GlobalOverviewComponent from "@/views/login/GlobalOverviewComponent";

export default {
    name: 'KeyCloakLogin',

    components: {GlobalOverviewComponent, TinyLogoComponent },

    /*
      For reference
      public final static int LOGIN_OK = 0;
      public final static int UNKNOWN_USER = 1;
      public final static int WRONG_PASSWORD = 2;
      public final static int USER_DISABLED = 3;
      public final static int USER_NO_SURVEYS = 4;
      public final static int LOGIN_OK_MULTIPLE = 5;
      public final static int LOGIN_OK_MULTIPLE_INDICATORS = 6;
      public static final int LOGIN_ACTAS = 7;
      public static final int LOGIN_WAIT = 8;
     */


    data: function () {
        return {
            changeUniverseState: false,
            cookieLogin: false,

            valid: false,

            showWaitScreen: false,

            loginFailed: false,
            isSubmitted: false,

            pollInterval: null,
            maxLoadCounter: null,

            selectedUniverse: null,
            actAsSelectedUniverse: null,

          actAsSelectedUser: null,
          actAsUsers: [],

          errorMessage: null
        }
    },

    computed: {
        ...mapState(['userIdentity', 'activeSurvey', 'activeDrillPath']),
        ...mapGetters(['getFilteredDrillPath', 'forwardToVGSServer', 'loginToFinnishUniverse', 'getAuthToken']),

        selectedUniverseLookup() {
          let uri = window.location.search.substring(1);
          let params = new URLSearchParams(uri);
          if(params.get("preselectedUniverse")) {
            return params.get("preselectedUniverse");
          }

          return this.selectedUniverse;
        },

        showLoadCounter() {
            return this.userIdentity && this.userIdentity.loginStatus===8
        },

        loadCounter() {
            return 100-Math.ceil( 100*this.userIdentity.loadStatus/this.maxLoadCounter)
        },

        textWelcome() {
            if(this.isSwedishServer) {
                return "Välkommen"
            } else if(this.isFTZOrAuto) {
                return "Velkommen"
            } else if(this.isFinlandServer) {
                return "Tervetuloa takaisin"
            }
            return "Welcome back"
        },

        textSignIn() {
            if(this.isSwedishServer) {
                return "Loggar in"
            } else if(this.isFTZOrAuto) {
                return "Logger på"
            } else if(this.isFinlandServer) {
                return "Kirjaudu sisään"
            }
            return "Signing into your account"
        },

        isKeyCloakMode() {
            return true
        },

        // isUniverseSelected() {
        //     return this.$route.query.universe
        // },

        textSupportMail() {
            return this.isSwedishServer ? "support@aganalytics.se" :  "support@aganalytics.dk"
        },

        isStagingServer() {
            return !this.isProductionServer && !this.isVGSServer && !this.isFTZServer && !this.isAutoFlowsServer && !this.isPostNordServer && !this.isFinlandServer
        },

        isProductionServer() {
            return window.location.origin.includes("dashboard.aganalytics.dk") || window.location.origin.includes("dashboard.aganalytics.se") /*|| window.location.origin.includes("dashboard.aganalytics.fi")*/ || window.location.origin.includes("dashboard.aganalytics.no")
        },

        isSwedishServer() {
            return this.isVGSServer || this.isPostNordServer
        },

        isVGSServer() {
            return window.location.origin.includes("vgs.aganalytics.se")
        },

        isFTZServer() {
            return window.location.origin.includes("ftz.auto-flows.com")
        },

        isPostNordServer() {
            return window.location.origin.includes("postnord.aganalytics.se")
        },

        isAutoFlowsServer() {
//      return true
            return window.location.origin.includes("dashboard.auto-flows.com")
        },

        isFTZOrAuto() {
            return this.isFTZServer || this.isAutoFlowsServer
        },

        isFinlandServer() {
            return window.location.origin.includes("dashboard.aganalytics.fi")
        },

        isUnknownUser() {
            return this.userIdentity && this.userIdentity.loginStatus===1
        },


        isSelectingUniverse() {
            return this.userIdentity && (this.userIdentity.loginStatus===5 || _.has(this.$route.query, 'change'))
        },

        isSelectingUniverseByIndicators() {
            return this.userIdentity && (this.userIdentity.loginStatus===6 && !_.has(this.$route.query, 'change'))
        },

        isChangingUniverse() {
            return _.has(this.$route.query, 'change')
        },

        isSelectingActAsUniverseUser() {
            return this.userIdentity && (this.userIdentity.loginStatus===7)
        },

      isSuggestDifferentLoginWithNewUser() {
        return this.userIdentity && (this.userIdentity.loginStatus===9)
      },

      keyCloakUserName() {
          return this.$keycloak && this.$keycloak.authenticated ? this.$keycloak.idTokenParsed.email : "?"
      }

    },

    created () {
        if (this.$keycloak && this.$keycloak.authenticated) {
            let urlParams = new URLSearchParams(window.location.search);
            this.selectedUniverse = urlParams.get('universe')
            this.authenticateOnDashboardWithKeyCloak()
        } else {
            alert("Not logged in!")
        }
    },

    beforeDestroy () {
        if(this.pollInterval!==null) {
            clearInterval(this.pollInterval)
            this.pollInterval = null
        }
    },

    methods: {

        authenticateOnDashboardWithKeyCloak() {

            const allowIndicators = !this.isChangingUniverse

            AuthService.authenticateKeyCloak(this.$keycloak.token, this.$keycloak.idToken, this.selectedUniverseLookup, allowIndicators).then((response) => {
                // console.log("back form authenticateKeyCloak")
                // console.log(response.data)

                const uid = response.data

                if(uid.loginStatus===8) {
                    this.selectedUniverse = uid.universe; // universe may have been picked for us
                    // console.log("assigned selectedUniverse=" + uid.universe)

                    // console.log(uid)
                    //
                    if(this.maxLoadCounter===null || uid.loadStatus>this.maxLoadCounter) {
                        this.maxLoadCounter = uid.loadStatus
                    }

                    if(this.pollInterval===null) {
                        this.pollInterval = setInterval(this.authenticateOnDashboardWithKeyCloak, 5000)
                    }

                } else if(this.pollInterval!==null) {
                    clearInterval(this.pollInterval)
                    this.pollInterval = null
                }

                this.$store.commit('keycloak', this.$keycloak)
                this.$store.commit('login', uid)
            })

        },


        onLogoutClicked() {
            if (this.$keycloak && this.$keycloak.authenticated) {
//                const redirectURL = window.location.origin
                const redirectURL = window.location.protocol + '//' + window.location.host
                var logoutOptions = {redirectUri: redirectURL}
                this.$keycloak.logoutFn(logoutOptions)
            }
        },

        onUniverseSelect: function (uniName, host) {
            // console.log("UniName: " + uniName + " host:" + host)
            // console.log(uniName)

            if(host) {
                window.location.href = host+"?universe="+uniName ;
            } else {
                this.selectedUniverse = uniName
                this.authenticateOnDashboardWithKeyCloak()
            }
        },

      onSelectedActAsUniverse: function (actAsUniverse) {
        this.actAsUsers = actAsUniverse.users
        this.actAsSelectedUser = actAsUniverse.adminUser
      },

      onSelectedActAsUser: function () {
        AuthService.impersonateUser(this.$keycloak.token, this.$keycloak.idToken, this.actAsSelectedUniverse.universeName, this.actAsSelectedUser).then((response) => {
          window.location.href=response.data.profileURL
        }).catch((error) => {
          this.errorMessage = error.response.data
        })
      },


    },

    watch: {
        userIdentity: function (newUserId) {
            this.showWaitScreen = false;

            if (newUserId.loginStatus === 0) {
              if(this.$route.query.redirect && this.$route.query.survey) {
                    this.$store.commit('selectSurvey', this.$route.query.survey)
                    this.$router.push(this.$route.query.redirect)
                } else {
                    if(newUserId.startSurvey) {
                        this.$store.commit('selectSurvey', newUserId.startSurvey)
                    }

                    PageNavigator.navigateToDefaultPage(this, this.activeSurvey, this.getFilteredDrillPath)
                    this.$store.dispatch('fetchBugsForSurvey', this.activeSurvey.extName)
                }

            } else if(newUserId.loginStatus === 5) {
                // console.log('Multi unis:' + newUserId.universes)
            }
        }
    }

}
</script>

<style scoped>

div.forgotSignupPanel a {
    color: #888888;
}

div.logocol {
    display: flex;
    justify-content: center;
}

div.mainTitle {
    justify-content: center;
    /*font-size: 30px;*/
    font-size: calc((30/16)*1rem);

}

div.subTitle {
    text-align: center;
    /*font-size: 18px;*/
    font-size: calc((18/16)*1rem);

}

div.footerRow {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    align-items: center;
    color: #888888
}

a.frontLink {
    margin-left: 6px;
    color: #888888;
    text-decoration: none;
}

div.waitContainer {
    display: flex;
    justify-content: center;
}

</style>

